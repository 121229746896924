// function getCookie(name) {
// 	var value = "; " + document.cookie;
// 	var parts = value.split("; " + name + "=");
// 	if (parts.length == 2) return parts.pop().split(";").shift();
// }
let height;
const sendPostMessage = () => {
  if (height !== document.querySelector('#direct-metal-roofing-facebook').offsetHeight) {
    height = document.querySelector('#direct-metal-roofing-facebook').offsetHeight;
    window.parent.postMessage({
      frameHeight: height
    }, '*');
    //console.log(height) // check the message is being sent correctly
  }
}

window.Client = {
	init: function() {
		// Do something on initialization
// 		var referrer = getCookie("referrer") || document.referrer;
// 		if(referrer){
// 		document.getElementById('input-31bb5ece').value = referrer;
// 		}
		
// 		var wdgtrk_source = getCookie("wdgtrk_source");
// 		if(wdgtrk_source){
// 		document.getElementById('input-31bb5ece').value = wdgtrk_source;
// 		}

        sendPostMessage();
	},

	onRender: function(screenId) {
		
		
        sendPostMessage();
        
        console.log(document.querySelector('#direct-metal-roofing-facebook').offsetHeight);
		
		
	}
}